import i18n from "@/libs/i18n"
import CreateBloodSampleForm from "@/views/PrintOrderForm/CreateBloodSampleForm.vue"
import CreateConsentCancerForm from "@/views/PrintOrderForm/CreateConsentCancerForm.vue"
import CreateExperimentTicket from "@/views/PrintOrderForm/CreateExperimentTicket.vue"
import CreateGeneTestForm from "@/views/PrintOrderForm/CreateGeneTestForm.vue"
import CreateKtrackForm from "@/views/PrintOrderForm/CreateKtrackForm.vue"
import CreateRequireForm from "@/views/PrintOrderForm/CreateRequireForm.vue"
import CreateTestTicket from "@/views/PrintOrderForm/CreateTestTicket.vue"

export const ORDERS_STATUS = {
  NOT_SEND_YET: 0,
  RECEIVE_ORDERS: 1,
  PROCESSING: 2,
  // SENDING: 3,
  FINISHED: 3,
  CANCELED: 4,
  WAITING_CONFIRM: 6,
  ERROR_PAYMENT: 7,
}

export const ORDERS_PRODUCT_STATUS = {
  RECEIVE_ORDERS: 1,
  PROCESSING: 2,
  STOCK_OUT: 3,
  DELIVERY: 4,
  FINISH: 5,
  CANCELED: 100,
}

export const ORDER_DELIVERY_STATES = [
  { order_number: 1, date: null, max_steps: 5, title: "Chờ xử lý", title_done: 'Đã tiếp nhận' },
  { order_number: 2, date: null, max_steps: 5, title: "Chưa xử lý", title_done: 'Đã xử lý' },
  { order_number: 3, date: null, max_steps: 5, title: "Xuất kho", title_done: 'Đã xuất kho' },
  { order_number: 4, date: null, max_steps: 5, title: "Giao hàng", title_done: 'Đã giao hàng' },
  { order_number: 5, date: null, max_steps: 5, title: "Hoàn thành", title_done: 'Hoàn thành' },
]

export const DELIVERY_STATUS = {
  RECEIVED: 1,
  SAMPLE_TAKEN: 2,
  PROCESSING: 3,
  PAYMENT: 4,
  SENDING: 5,
  FINISHED: 6,
  DELIVERED: 7,
}

export const PRODUCT_TYPES = {
  PRODUCT: 1,
  SERVICE: 2,
}

export const PRODUCT_TYPES_DATA = [
  {
    name: "Product",
    name_vi: 'Sản phẩm',
    value: 1,
  },
  {
    name: "Service",
    name_vi: 'Dịch vụ',
    value: 2,
  },
]

export const PRODUCT_ON_HODO = {
  UNAVAILABLE: 1,
  PENDING_AVAILABLE: 2,
  AVAILABLE: 3,
  REJECT_AVAILABLE: 4,
  ARCHIVE_AVAILABLE: 50,
}

export const PRODUCT_ON_INVENTORY_STATUS = {
  AVAILABLE: 1,
  UNAVAILABLE: 2,
}

export const PRODUCT_CERTIFICATES_STATES = {
  DRAFF: 0,
  WAITING: 1,
  VERIFIED: 2,
  REJECTED: 3,
}

export const ORDER_FORM_STATUS_OPTIONS = {
  PENDING: 1,
  ACCEPT: 2,
  REJECT: 3,
}
export const SUPPLIER_ORDERS_STATUS = {
  CANCELED: 100,
}

export const MESSAGE_CODE_LOGIN_ERROR = 12345
export const MESSAGE_CODE_LOGIN_INCORRECT = i18n.t(
  "lbl_error_check_email_and_password"
)

export const ROLES_SYSTEM = {
  ALL: "All",
  SUPPLIER: 1,
  PROCESSOR: 2,
  SUPPLIER_PRODUCT: 3,
}

export const ORDER_FORM_CONFIG_STATUS = {
  PENDING: 1,
  ACCEPT: 2,
  REJECT: 3,
}

export const ORDER_FORM_PROCESSOR_STATUS = {
  PENDING: 1,
  PROCESSING: 2,
  COMPLETE: 3,
  CANCEL: 4,
  SUPPLIER_CANCEL: 100,
}

export const ORDER_PROCESSOR_STATUS = {
  NOT_COMPLETE: 1,
  COMPLETE: 2,
  CANCEL: 3,
}

export const HODOCONFIG_FIREBASE = {
  firebaseKey:
    "BB-wTadF8XkPvbqf0popurYLgNUiqE18PzDtyZCSLZD_INwc7ajHz8LDY4UXDieERpG5dyXMnnqyaHsq-qOOUpY",
}

export const RESULT_ORDER_ITEM_STATUS = {
  NOT_APPROVE: 1,
  APPROVE: 2,
}

export const ORDER_FORM_DRAFT = {
  REQUISITION_FORM: 1,
  RESULT_FORM: 2,
  RESULT_PROCESSOR: 3,
}

export const NOTIFICATION_TYPE = {
  SUPPLIER: 1,
  PROCESSOR: 2,
}

export const NOTIFICATION_STATUS_TYPE = {
  CREATE: 1,
  SUPPLIER_UPDATE: 2,
  PROCESSOR_UPDATE: 3,
  SUPPLIER_REJECT_LAB: 4,
  NEW_COMMENT: 5,
}

export const RESULT_FORM_TYPE = {
  SUPPLIER: 1,
  PROCESSOR: 2,
}

export const LIST_FORM = [
  {
    form_type: 12,
    name: "Phiếu đồng thuận thực hiện xét nghiệm tầm soát nguy cơ ung thư di truyền",
    component: CreateConsentCancerForm,
    print_preview_router: "print-consent-cancer-form",
  },
  {
    form_type: 13,
    name: "Phiếu đồng thuận xét nghiệm sàng lọc tiền sinh không xâm lấn - NIPT",
    component: CreateTestTicket,
    print_preview_router: "print-nipt",
  },
  {
    form_type: 14,
    name: "Phiếu mẫu máu, mẫu ối tươi, gai nhau",
    component: CreateBloodSampleForm,
    print_preview_router: "print-blood-sample-form",
  },
  {
    form_type: 15,
    name: "Phiếu đồng thuận thực hiện xét nghiệm G4500",
    print_preview_router: "print-g4500",
    component: CreateExperimentTicket,
  },
  {
    form_type: 16,
    name: "Phiếu yêu cầu xét nghiệm",
    component: CreateRequireForm,
    print_preview_router: "print-require-form",
  },
  {
    form_type: 17,
    name: "Phiếu đồng thuận thực hiện xét nghiệm gen",
    component: CreateGeneTestForm,
    print_preview_router: "print-gene-test-form",
  },
  {
    form_type: 18,
    name: "Phiếu đồng thuận thực hiện xét nghiệm K-Track",
    component: CreateKtrackForm,
    print_preview_router: "print-ktrack",
  },
]
export const PAYMENT_TYPE = [
  {
    label: i18n.t("lbl_not_payment"),
    value: 1,
    class: "text-warning",
  },
  {
    label: i18n.t("lbl_paid"),
    value: 2,
    class: "text-success",
  },
  // {
  //   label: i18n.t("lbl_canceled_bill"),
  //   value: 3,
  //   class: "text-danger",
  // },
]

export const SUPPLIER_PROCESS_STATUS = {
  not_send: 1,
  processing: 2,
  completed: 3,
  canceled: 4
}

export const TRANSACTION_SLIP_TYPE = {
  Ws: 1,
  Supplier: 2
}

export const RECEIVING_TYPE = {
  receiving: 1,
  beginPeriod: 2,
}


export const PRODUCT_ORDER_STATUS = {
  RECEIVED: 1,
  PAYMENT: 2,
  STOCKOUT: 3,
  DELIVERIED: 4,
  SENDING: 5,
  FINISHED: 6,
  ARR: [
    1, 2, 3, 4, 5, 6
  ]
}

export const PAYMENT_METHOD = {
  offline: 1,
  online: 2,
  points: 3
}

export const ECOM_PRODUCT_PRESCRIPTION = {
  required: 2,
  unRequired: 1
}

export const ECOM_PRODUCT_VIEW = {
  view: 2,
  notView: 1
}


export const TYPE_CATE = {
  category: 1,
  dosage: 2,
  packaging: 3,
  unit: 4,
  manufacture: 5,
  brand: 6,
  origin: 7,
}

export const MARKETPLACE_PRODUCT_TYPES = {
  medicine: 1,
  others: 2
}

export const SERVICE_GROUP_TYPE = {
  test: 1,
  imagingDiagnostic: 2,
  other: 3,
  molecularBiology: 4,
  patientVisit: 5
}

export const EXPIRED_RULE_CREDIT = {
  loopReset: 1,
  prefixReset: 2
}

export const HODO_CREDIT_TYPE = {
  deposit: 1,
  accumulate: 2,
  redemption: 3,
}
export const HODO_MEMBERSHIP = [
  {
    text: 'HODO Normal',
    value: 4
  },
  {
    text: 'HODO Silver',
    value: 1
  },
  {
    text: 'HODO Silver Plus',
    value: 2
  },
  {
    text: 'HODO Gold',
    value: 3
  },
]

export const VOUCHER_TYPE = {
  money: 1,
  percent: 2
}

export const STATUS_RETURN_RESULT = [
  {
    text: i18n.t('Chọn trạng thái trả KQ'),
    value: ''
  },
  {
    text: i18n.t('Chưa trả KQ'),
    value: 1
  },
  {
    text: i18n.t('Cần bổ sung KQ'),
    value: 2
  },
  {
    text: i18n.t('Đã trả KQ'),
    value: 3
  }
]

export const TYPE_RETURN_RESULT = {
  EMPTY: 1,
  PROCESSOR: 2,
  SUPPLIER: 3
}

export const RESULT_TYPE = {
  PROCESSOR: 1,
  SUPPLIER: 2
}

export const RESULT_PROCESSOR_STATUS = {
  SENT: 2 // processor đã gửi KQ 
}
export const DELIVERY_CONFIRM_STATUS = {
  NOT_CONFIRM: 1,
  UPLOADED_FILE: 2,
  CONFIRMED: 3
}


// CAMPAIGN
export const TYPE_MODAL = {
  CREATE: 0,
  EDIT: 1,
  VIEW: 2,
}

export const VOUCHER_GROUP_STATUS = {
  ACTIVE: 1,
  IN_ACTIVE: 2,
}