import HIDBaseRequest from "../HIDBaseRequest";

export default class CampaignRequest extends HIDBaseRequest {
  getCampaigns(params) {
    return this.get("go/comm/get-campaigns", params);
  }
  getCampaign(id) {
    return this.get(`go/comm/get-campaigns/${id}`);
  }
  addCampaign(data) {
    return this.post(`go/comm/create-voucher-campaign`, data);
  }
  updateCampaign(data) {
    return this.put(`go/comm/update-campaigns`, data);
  }
  getVouchers(params) {
    return this.get("go/comm/get-campaigns/vouchers", params);
  }
  getAllVouchers(params) {
    return this.get("go/comm/get-campaigns/vouchers", params);
  }
  createVoucherGroup(params) {
    return this.post("go/comm/create-voucher-group", params);
  }
  updateVoucherGroup(params) {
    return this.put(`go/comm/update-voucher-group`, params);
  }
  createVouchers(params) {
    return this.post("go/comm/generate-voucher-code", params);
  }
  addProducts(params) {
    return this.post("go/comm/add-product-to-voucher-campaign", params);
  }
  getVoucherGroups(id, params) {
    return this.get(`go/comm/get-campaigns/voucher-group/${id}`, params);
  }
  getProductGroups(params) {
    return this.get("go/comm/get-campaigns/products", params);
  }
  checkVoucherCode(code, params) {
    return this.get(`go/comm/get-voucher/${code}`, params);
  }
  redeemVoucherCode(code) {
    return this.put(`go/comm/redeem-voucher/${code}`);
  }
  getReportsVoucher(params) {
    return this.get(`go/comm/reports-voucher`, params);
  }
  updateStatusVoucherGroup(params) {
    return this.put(`go/comm/voucher-group-status`, params);
  }
  checkExistGroupName(params) {
    return this.post(`go/comm/existed-group-voucher-name`, params);
  }
}
