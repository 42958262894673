import "@/@fake-db/db";
// Global Components
import "./global-components";
// 3rd party plugins
import "@/libs/portal-vue";
import "@/libs/toastification";

import BootstrapVue, {
  BootstrapVueIcons,
  ModalPlugin,
  ToastPlugin,
  VBTooltipPlugin,
} from "bootstrap-vue";

import VueQrcode from "@chenfengyuan/vue-qrcode";
import VueCompositionAPI from "@vue/composition-api";
import moment from "moment";
import Vue from "vue";
import infiniteScroll from "vue-infinite-scroll";
import acl from "./acl";
import RequestFactory from "./api/RequestFactory";
import App from "./App.vue";
import i18n from "./libs/i18n";
import router from "./router";
import store from "./store";
import { ROLES_SYSTEM } from "./utils/constant";

Vue.use(infiniteScroll);
window.axios = require("axios");
Vue.prototype.$rf = new RequestFactory();

Vue.component(VueQrcode.name, VueQrcode);
Vue.prototype.moment = moment;
window.moment = moment;

const role = Vue.observable({ role: ROLES_SYSTEM.ALL });
Object.defineProperty(Vue.prototype, "$role", {
  get() {
    return role.role;
  },

  set(value) {
    role.role = value;
  },
});

const user = Vue.observable({ user: null });
Object.defineProperty(Vue.prototype, "$user", {
  get() {
    return user.user;
  },

  set(value) {
    user.user = value;
  },
});

Vue.directive("click-outside", {
  bind: function (el, binding, vnode) {
    el.event = function (event) {
      if (!(el == event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("click", el.event);
  },
  unbind: function (el) {
    document.body.removeEventListener("click", el.event);
  },
});

Vue.use(BootstrapVue, {
  breakpoints: [`xs`, "sm", "md", "lg", "xl", "xxl"],
});
Vue.use(BootstrapVueIcons);

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);
Vue.use(VBTooltipPlugin);

// Composition API
Vue.use(VueCompositionAPI);

Vue.use(acl);

// import core styles
require("@core/scss/core.scss");

// import assets styles
require("@/assets/scss/style.scss");

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
