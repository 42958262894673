import BaseRequest from "../BaseRequest";
import appUtils from "../../utils/appUtils";

export default class AuthRequest extends BaseRequest {
  login(data = {}) {
    return this.post("go/auth/login", data);
  }

  logout() {
    return this.post("auth/logout");
  }

  me() {
    try {
      // return this.get('auth/me')
      // return this.get('auth/doctor/me')
      return this.get("auth/doctor/v2/me");
    } catch (e) {
      appUtils.removeLocalUser();
      appUtils.removeLocalToken();

      return window.$router.push({ path: "/login" });
    }
  }
  goMe() {
    return this.getLocal("go/core/auth/doctor/me");
  }
  updateTokenDevices(params) {
    return this.postLocal("go/core/devices", params);
  }
  deleteTokenDevices(id) {
    return this.deleteLocal(`go/core/devices/${id}`);
  }
  getRoleInfo() {
    return this.getLocal("go/partner_user");
  }
  getAllPermissions() {
    return this.getLocal("go/partner_permission");
  }
  getAllPartnerRoles() {
    return this.getLocal("go/partner_role");
  }
  getRolePermissionsByRoleId(payload) {
    const { roleId, params } = payload;
    return this.get(`go/partner_role_permission/role/${roleId}`, params);
  }
  checkExistedPartnerUser(data) {
    return this.post(`go/partner_user/check_existed_user`, data);
  }
  getListPartnerUsers(params) {
    return this.get(`go/partner_user/all`, params);
  }
  updatePartnerUser(id, data) {
    return this.put(`go/partner_user/${id}`, data);
  }
  deletePartnerUser(id) {
    return this.delete(`go/partner_user/${id}`);
  }
  addPartnerUser(data) {
    return this.post(`go/partner_user`, data);
  }
}
