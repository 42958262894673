import { getMessaging, getToken } from "firebase/messaging";

import { HODOCONFIG_FIREBASE } from "@/utils/constant";
import Vue from "vue";
import appUtils from "@/utils/appUtils";
import { initializeApp } from "firebase/app";
import store from "@/store";

// import axios from 'axios'
// import axios from "@/libs/axios";

export function registerFirebase() {
  const userData = appUtils.getLocalUser();
  if (userData) {
    if ("serviceWorker" in navigator && "PushManager" in window) {
      console.log("Service Worker and Push is supported");

      navigator.serviceWorker
        .register("firebase-messaging-sw.js?v=1605238", {
          useCache: false,
        })
        .catch(function (error) {
          console.error("Service Worker Error", error);
        })
        .then((subscription) => {
          var firebaseConfig = {
            apiKey: "AIzaSyDvMMgJWMS8vgtfslZJ56byo3akYbfupZM",
            authDomain: "hodo-46abc.firebaseapp.com",
            databaseURL: "https://hodo-46abc.firebaseio.com",
            projectId: "hodo-46abc",
            storageBucket: "hodo-46abc.appspot.com",
            messagingSenderId: "923239105015",
            appId: "1:923239105015:web:c8958b39259abffa15eef2",
            measurementId: "G-Y47WD5M5E1",
          };

          initializeApp(firebaseConfig);
          const messaging = getMessaging();

          return getToken(messaging, {
            vapidKey: HODOCONFIG_FIREBASE.firebaseKey,
          })
            .then((currentToken) => {
              if (currentToken) {
                // Send the token to your server and update the UI if necessary
                console.log("Token is:", currentToken);
                store.dispatch(
                  "authenticate/handleUpdateTokenDevices",
                  currentToken
                );

                return currentToken;
                // ...
              } else {
                // Show permission request UI
                console.log(
                  "No registration token available. Request permission to generate one."
                );
                // ...
              }
            })
            .catch((err) => {
              console.log("An error occurred while retrieving token. ", err);
              // ...
            });
          //   onMessage(messaging, (payload) => {
          //   console.log('Message received. ', payload);

          // });
        })
        .then((token) => {
          console.log("Receive message");
          return token;
        });
    } else {
      console.warn("Push messaging is not supported");
    }
  }
}

function clearCookie(name, domain, path) {
  var _domain = domain || document.domain;
  var _path = path || "/";
  document.cookie = `${name}=; expires=${+new Date()}; domain=${_domain}; path=${_path}`;
}

export default {
  namespaced: true,
  state: {
    token: null,
    permissions: [],
    userLogin: {},
    regionsAcept: "",
    regions: "",
    is_test_provider: 1,
    tokenFirebase: "",
    deviceInfo: {},
    orgInfo: {},
    role: "",
    listPermissions: [],
    listRoles: [],
    listRolePermissions: [],
  },
  getters: {
    isAuthenticated(state) {
      return state.token != null;
    },
    userLogin(state) {
      return state.userLogin;
    },
    regions(state) {
      return state.regions;
    },
    regionsAcept(state) {
      return state.regionsAcept;
    },
    listPermissions(state) {
      return state.listPermissions;
    },
    listRoles(state) {
      return state.listRoles;
    },
    listRolePermissions(state) {
      return state.listRolePermissions;
    },
  },
  mutations: {
    clearToken(state) {
      state.token = null;
      state.tokenFirebase = null;
      state.deviceInfo = {};
    },
    setRegionsAcept(state, regionsAcept) {
      state.regionsAcept = regionsAcept;
    },
    setRegions(state, regions) {
      state.regions = regions;
    },
    setUserLogin(state, userLogin) {
      state.userLogin = userLogin;
    },
    setToken(state, token) {
      state.token = token;
    },
    setPermissions(state, permissions) {
      state.permissions = permissions;
    },
    clearPermissions(state) {
      state.permissions = [];
    },
    setIsTestProvider(state, data) {
      return (state.is_test_provider = data);
    },
    setTokenFirebase(state, token) {
      return (state.tokenFirebase = token);
    },
    setDeviceInfo(state, data) {
      state.deviceInfo = data;
    },
    setOrgInfo(state, data) {
      state.orgInfo = data;
    },
    setRoleUser(state, data) {
      state.role = data;
    },
    setListPermissions(state, data) {
      state.listPermissions = data;
    },
    setListRoles(state, data) {
      state.listRoles = data;
    },
    setListRolePermissions(state, data) {
      state.listRolePermissions = data;
    },
  },
  actions: {
    login(ctx, credentials) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest("AuthRequest")
          .login(credentials)
          .then(async (result) => {
            if (result?.code === 200) {
              const res = result.data;
              appUtils.setLocalToken(res?.access_token);
              appUtils.setLocalTokenExpiration(
                new Date().getTime() + 3600 * 1000 * 24
              );
              ctx.dispatch("setLogoutTimer", 3600 * 1000 * 24);
              ctx.commit("setToken", res?.access_token);
              await ctx.dispatch("authMe");
              // await ctx.dispatch("getRoleInfo")
              // await ctx.dispatch("getAllPermissions")
            }
            resolve({
              success: true,
            });
          })
          .catch((error) => {
            console.log(error);
            reject(error.message);
          });
      });
    },

    async authMe(ctx) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest("AuthRequest")
          .goMe()
          .then(async (result) => {
            if (result?.status === 200) {
              const res = result.data?.data || {};
              const newUserInfo = {
                ...res,
                // supplierInfo: res?.suppliers?.[0],
                // processorsInfo: res?.processors?.[0],
              };

              // const isOwnerOrAdminSupplier = [1, 2].includes(
              //   newUserInfo?.supplierInfo?.role
              // )

              // const isOwnerOrAdminProcessor = [1, 2].includes(
              //   newUserInfo?.processorsInfo?.role
              // )

              // if (!isOwnerOrAdminSupplier && !isOwnerOrAdminProcessor) {
              //   ctx.commit("clearToken")
              //   appUtils.removeAll()
              //   reject({
              //     message: MESSAGE_CODE_LOGIN_ERROR,
              //   })

              //   return;
              // } else {
              appUtils.setLocalUser(JSON.stringify(newUserInfo));
              ctx.commit("setUserLogin", newUserInfo);
              await ctx.dispatch("getRoleInfo");
              await ctx.dispatch("getAllPermissions");
              await ctx.dispatch("getAllPartnerRoles");
              // ctx.dispatch("updateTokenDevices");
              resolve({
                success: true,
              });
              // }
            }
          })
          .catch((error) => {
            reject(error.message);
          });
      });
    },

    async initAuth(ctx) {
      const token = appUtils.getLocalToken();
      const tokenExpiration = appUtils.getLocalTokenExpiration();
      if (!token || !tokenExpiration) {
        return ctx.dispatch("logout");
      }

      if (new Date().getTime() > tokenExpiration || !token) {
        return ctx.dispatch("logout");
      }
      // registerFirebase();

      ctx.commit("setToken", token);
      ctx.dispatch("setLogoutTimer", tokenExpiration - new Date().getTime());
      await ctx.dispatch("authMe");
      return true;
    },
    setLogoutTimer(ctx, duration) {
      setTimeout(() => {
        ctx.commit("clearToken");
      }, duration);
    },
    async logout(ctx, callback) {
      const deviceId = ctx.state.deviceInfo?.ID;
      if (!deviceId) {
        ctx.commit("clearToken");
        localStorage.removeItem("deviceId");

        appUtils.removeAll();
        callback && callback();
        return;
      }

      await Vue.prototype.$rf
        .getRequest("AuthRequest")
        .deleteTokenDevices(deviceId)
        .then(() => {})
        .finally(() => {
          ctx.commit("clearToken");
          localStorage.removeItem("deviceId");

          appUtils.removeAll();
          callback && callback();
        });
    },
    handleUpdateTokenDevices(ctx, token) {
      let deviceId = localStorage.getItem("deviceId");
      let uuid = "";
      if (!deviceId) {
        uuid = appUtils.uuid();
        localStorage.setItem("deviceId", uuid);
      }

      ctx.commit("setTokenFirebase", token);
      const params = {
        device_id: uuid,
        device_token: token,
        device_type: "web",
        user_id: ctx.state.userLogin?.id,
      };

      Vue.prototype.$rf
        .getRequest("AuthRequest")
        .updateTokenDevices(params)
        .then((res) => {
          const deviceInfo = res?.data?.device;
          if (!deviceInfo) return;

          ctx.commit("setDeviceInfo", deviceInfo);
        });
    },
    async getRoleInfo(ctx) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest("AuthRequest")
          .getRoleInfo()
          .then((res) => {
            ctx.commit("setRoleUser", res.data[0]?.partner_role?.role_name);
            ctx.commit("setOrgInfo", res.data[0]?.partner_info);
            ctx.commit(
              "setPermissions",
              res.data[0]?.partner_role?.role_permissions?.map(
                (elm) => elm.permission_info
              )
            );
            let orgInfo = { ...res.data[0]?.partner_info };
            if (res.data[0].partner_type === 2) {
              orgInfo = {
                ...res.data[0]?.partner_info,
                id: res.data[0]?.partner_info?.processor_id,
              };
            }
            if (res.data[0].partner_type === 1) {
              orgInfo = {
                ...res.data[0]?.partner_info,
                id: res.data[0]?.partner_info?.supplier_id,
              };
            }

            appUtils.setLocalUser(
              JSON.stringify({
                ...store.state.authenticate.userLogin,
                supplierInfo: orgInfo,
                processorsInfo: orgInfo,
              })
            );
            ctx.commit("setUserLogin", {
              ...store.state.authenticate.userLogin,
              supplierInfo: orgInfo,
              processorsInfo: orgInfo,
            });
            resolve(res);
          })
          .catch((error) => reject(error));
      });
    },
    async getAllPermissions(ctx) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest("AuthRequest")
          .getAllPermissions()
          .then((response) => {
            resolve(response);
            ctx.commit("setListPermissions", response.data);
          })
          .catch((error) => reject(error));
      });
    },
    async getAllPartnerRoles(ctx) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest("AuthRequest")
          .getAllPartnerRoles()
          .then((response) => {
            resolve(response);
            ctx.commit("setListRoles", response.data);
          })
          .catch((error) => reject(error));
      });
    },
    async getRolePermissions(ctx, payload) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest("AuthRequest")
          .getRolePermissionsByRoleId(payload)
          .then((response) => {
            resolve(response);
            ctx.commit("setListRolePermissions", response.data);
          })
          .catch((error) => reject(error));
      });
    },
  },
};
