import { createAcl, defineAclRules } from "vue-simple-acl";

import store from "@/store/index";
import VueCompositionAPI from "@vue/composition-api";
import Vue from "vue";
import router from "../router";

Vue.use(VueCompositionAPI);

const user = () => {
  const common = true;
  const permissions =
    store.state.authenticate.permissions?.map((elm) => elm?.permission_name) ||
    [];
  return {
    common,
    permissions,
  };
};
const rules = () =>
  defineAclRules(async (setRule) => {
    // const response = await store.dispatch('authenticate/getAllPermissions')
    // const listPermissions = response.data?.map(elm => elm.permission_name) || []
    setRule("common", (user) => user().common);
    setRule("service-orders", (user) =>
      user().permissions.includes("service-orders")
    );
    setRule("service-management", (user) =>
      user().permissions.includes("service-management")
    );
    setRule("service-result", (user) =>
      user().permissions.includes("service-result")
    );
    setRule("report", (user) => user().permissions.includes("report"));
    setRule("configs", (user) => user().permissions.includes("configs"));
    setRule("config-roles", (user) =>
      user().permissions.includes("config-roles")
    );
    setRule("product-orders", (user) =>
      user().permissions.includes("product-orders")
    );
    setRule("campaigns", (user) => user().permissions.includes("campaigns"));
    setRule("campaign-reports", (user) =>
      user().permissions.includes("campaign-reports")
    );
    setRule("product-management", (user) =>
      user().permissions.includes("product-management")
    );
    setRule("processor-service-orders", (user) =>
      user().permissions.includes("processor-service-orders")
    );
    setRule("processor-service-result", (user) =>
      user().permissions.includes("processor-service-result")
    );
    // listPermissions?.forEach(item => {
    //     setRule(`${item}`, user => {
    //         return user().permissions?.includes(`${item}`)
    //     })
    // })
  });

const simpleAcl = createAcl({
  user,
  rules,
  router,
});

export default simpleAcl;
